import { AxiosError, AxiosHeaders, type AxiosResponse } from 'axios';

import { ACCOUNTS_LIST, GROUPS_LIST, TOKENS_LIST } from '@/constants/table';
import { STATUS_ERROR, STATUS_SUCCESS } from '@/constants/status';

import {
  type AllowedRoutes,
  LOGIN_ROUTE,
  MANAGE_ACCOUNT_ROUTE,
  MANAGE_GROUPS_ROUTE,
  MANAGE_TOKEN_ROUTE
} from '@/constants/routes';

import {
  IDEA_DATABASE_USER_ADMIN,
  PATENTS_USER_ROLE_NAMES
} from '@/constants/user';

import { DOUBLE_WHITESPACE_REGEX } from '@/constants/regex';
import { ORGANIZATION_PRODUCTS } from '@/constants/organization';

import { TinyEmitter } from 'tiny-emitter';

import type {
  ApiErrorField,
  ApiErrorResponse,
  ParsedApiErrors
} from '@/types/api';

import type {
  ListContentType,
  ValidationMessages,
  ValidationStatuses
} from '@/types/components';

import type {
  CurrentUser,
  IdeaDatabaseUserRole,
  IdeaDatabaseUserRoleParam,
  PatentsPlatformUserRole
} from '@/types/users';

import type { HTMLAttributes } from 'vue';
import type { Organization } from '@/types/organizations';
import type { TFunction } from 'i18next';

export function prepareMessage(
  status: ValidationStatuses,
  messages: ValidationMessages
) {
  if (status === STATUS_ERROR) return messages.errorMessage;
  if (status === STATUS_SUCCESS) return messages.successMessage;
  return '';
}

// INFO: Please replace the original function with this one when installing new shadcn components.
export function createDelegatedProps<
  T extends {
    class?: HTMLAttributes['class'];
  }
>(props: T) {
  const { class: _, ...delegated } = props;

  return delegated;
}

// Adds information about active products for the current user
export function parseCurrentUserActiveProducts(currentUser: CurrentUser) {
  return {
    ...currentUser,
    isPatentsPlatformAvailable:
      currentUser.organization.activeProducts.includes(
        ORGANIZATION_PRODUCTS.IAMIP_PATENTS_PLATFORM
      ),
    isIdeaDatabaseAvailable: currentUser.organization.activeProducts.includes(
      ORGANIZATION_PRODUCTS.IAMIP_IDEA_DATABASE
    ),
    isPatentsApiAvailable: currentUser.organization.activeProducts.includes(
      ORGANIZATION_PRODUCTS.IAMIP_PATENTS_API_CONTAINERS
    )
  };
}

export function parseUpdatedOrganizationDetails(
  currentUser: CurrentUser,
  organization: Organization
) {
  const updatedAvailableOrganizations = currentUser.availableOrganizations.map(
    availableOrganization =>
      availableOrganization.id == organization.id
        ? organization
        : availableOrganization
  );

  const updatedOrganization =
    currentUser.organization.id == organization.id
      ? organization
      : currentUser.organization;

  return {
    ...currentUser,
    availableOrganizations: updatedAvailableOrganizations,
    organization: updatedOrganization
  };
}

/**
 * Returns a parsed error object with fields and messages
 * Example:
 * if field is string based => { field: 'messages' }
 * if field is array based => { field: [ [arrayIndex, 'messages'] ] }
 */
export function parseErrorFields(errors: ApiErrorField) {
  const errorEntries = Object.entries(errors).map(errorEntry => [
    errorEntry[0].split('.'),
    errorEntry[1]
  ]);

  return errorEntries.reduce((acc, [[errorKey, errorIndex], value]) => {
    const itemValue = Array.isArray(value) ? value.join(' ') : value;

    if (errorIndex) {
      if (!acc[errorKey]) {
        acc[errorKey] = [];
      }

      (acc as Record<string, string[]>)[errorKey][Number(errorIndex)] =
        itemValue;

      return acc;
    }

    acc[errorKey] = itemValue;

    return acc;
  }, {} as ParsedApiErrors);
}

export function parseError(error: Error | ApiErrorResponse): string {
  if (error instanceof AxiosError) {
    if (error.response?.data.message) {
      return error.response.data.message;
    }

    return error.response?.data?.errors
      ? Object.values(error.response.data.errors).flat().join(' ')
      : `${error.message}${error.response?.statusText ? ` (${error.response?.statusText})` : ''}`;
  }

  return error.message;
}

export function parsePatentPlatformRoles(
  roles: PatentsPlatformUserRole[],
  t: TFunction
) {
  return roles
    .sort((a, b) => a - b)
    .map((role: PatentsPlatformUserRole) => t(PATENTS_USER_ROLE_NAMES[role]));
}

export function parseIdeaDatabaseRoles(
  roles: string[] | number[] | null
): string[] | null {
  if (!roles) return roles;
  return roles.map(role =>
    typeof role === 'number' ? IDEA_DATABASE_USER_ADMIN : role
  );
}

export function createIdeaDatabaseRolesParams(
  roles: IdeaDatabaseUserRole[] | ReadonlyArray<IdeaDatabaseUserRole>
): IdeaDatabaseUserRoleParam[] {
  return roles.map(role => (role === IDEA_DATABASE_USER_ADMIN ? 1 : role));
}

export function onLogout() {
  setTimeout(function () {
    document.cookie =
      'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    localStorage.clear();
    window.location.href = LOGIN_ROUTE;
  }, 1000);
}

export function getCurrentListContentType(
  pathname: AllowedRoutes
): ListContentType | undefined {
  if (pathname === MANAGE_ACCOUNT_ROUTE) {
    return ACCOUNTS_LIST;
  }

  if (pathname === MANAGE_GROUPS_ROUTE) {
    return GROUPS_LIST;
  }

  return pathname === MANAGE_TOKEN_ROUTE ? TOKENS_LIST : undefined;
}

// Creates a temporary link for the file download
export function handleDownloadAction(blob: Blob, fileName: string) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.download = fileName;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

// Extracts filename from Content-Disposition header.
export function parseContentDispositionHeaders(
  response: AxiosResponse<Blob>
): string {
  const contentDisposition = new Headers(response.headers as AxiosHeaders).get(
    'Content-Disposition'
  ) as string;

  const utfName = contentDisposition.split(`; filename*=utf-8''`)[1]?.trim();

  return decodeURIComponent(
    utfName || contentDisposition.split('filename="')[1]?.trim()
  )
    .replace('"', '')
    .replace(DOUBLE_WHITESPACE_REGEX, ' ');
}

const emitter = new TinyEmitter();
export function emitterInstance() {
  return emitter;
}
