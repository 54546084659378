import { parsePatentPlatformRoles } from '@/lib/helpers';

import {
  PRODUCT_IDEA_DATABASE,
  PRODUCT_PATENT_PLATFORM
} from '@/constants/contentType';

import {
  IDEA_DATABASE_ROLES_NAMES,
  PATENTS_FULL_USER,
  PATENTS_USER_TYPES
} from '@/constants/user';

import { HTTP_CODES } from '@/constants/api';

import type { IdeaDatabaseUserRole, User } from '@/types/users';

import type { TFunction } from 'i18next';
import type { ListToken } from '@/types/tokens';

// Common Modals
export const INACTIVE_MODAL = '';
export const LOADING_MODAL = 'LOADING_MODAL';
export const WARNING_MODAL = 'WARNING_MODAL';
export const INFORMATION_MODAL = 'INFORMATION_MODAL';
export const ERROR_MODAL = 'ERROR_MODAL';

// Table Modals
export const DELETE_ENTITY_MODAL = 'DELETE_ENTITY_MODAL';
export const INVALID_DATA_MODAL = 'INVALID_DATA_MODAL';

// Accounts Modals
export const CREATE_EDIT_ACCOUNT_MODAL = 'CREATE_EDIT_ACCOUNT_MODAL';
export const VIEW_ACCOUNT_MODAL = 'VIEW_ACCOUNT_MODAL';
export const ADD_EDIT_NOTE_MODAL = 'ADD_EDIT_NOTE_MODAL';
export const SELECT_PRODUCT_MODAL = 'SELECT_PRODUCT_MODAL';
export const TRIGGER_RESET_PASSWORD_MODAL = 'TRIGGER_RESET_PASSWORD_MODAL';

// Bulk Accounts Modals
export const BULK_CREATE_NOTES_MODAL = 'BULK_CREATE_NOTES_MODAL';
export const BULK_CHANGE_USER_STATUS_MODAL = 'BULK_CHANGE_USER_STATUS_MODAL';
export const BULK_CHANGE_USER_GROUPS_MODAL = 'BULK_CHANGE_USER_GROUPS_MODAL';
export const BULK_DELETE_ACCOUNTS_MODAL = 'BULK_DELETE_ACCOUNTS_MODAL';
export const BULK_DELETE_NOTES_MODAL = 'BULK_DELETE_NOTES_MODAL';
export const BULK_CHANGE_USER_PRODUCT_ACCESS_MODAL =
  'BULK_CHANGE_USER_PRODUCT_ACCESS_MODAL';
export const BULK_CHANGE_USER_LOGIN_TYPE_MODAL =
  'BULK_CHANGE_USER_LOGIN_TYPE_MODAL';

// Groups Modals
export const CREATE_EDIT_GROUP_MODAL = 'CREATE_EDIT_GROUP_MODAL';
export const VIEW_GROUP_MODAL = 'VIEW_GROUP_MODAL';
export const BULK_DELETE_GROUPS_MODAL = 'BULK_DELETE_GROUPS_MODAL';
export const BULK_ADD_TO_GROUPS_MODAL = 'BULK_ADD_TO_GROUPS_MODAL';

// Token Modals
export const CREATE_EDIT_TOKEN_MODAL = 'CREATE_EDIT_TOKEN_MODAL';
export const VIEW_TOKEN_MODAL = 'VIEW_TOKEN_MODAL';
export const BULK_DELETE_TOKENS_MODAL = 'BULK_DELETE_TOKENS_MODAL';
export const BULK_CHANGE_TOKENS_STATUS_MODAL =
  'BULK_CHANGE_TOKENS_STATUS_MODAL';

// User Settings Modals
export const UPDATE_USER_PASSWORD_MODAL = 'UPDATE_USER_PASSWORD_MODAL';
export const PASSWORD_CONFIRMATION_REQUIRED_MODAL =
  'PASSWORD_CONFIRMATION_REQUIRED_MODAL';

// Organization User Preset Modals
export const CREATE_EDIT_ORGANIZATION_USER_PRESET_MODAL =
  'CREATE_EDIT_ORGANIZATION_USER_PRESET_MODAL';

export const USER_HISTORY_ACTION_DELETE = 'userOrganizationHistory.delete';

export const DISABLE_ERROR_API_CODES = [
  HTTP_CODES.NOT_FOUND,
  HTTP_CODES.BAD_REQUEST
] as const;

export const INITIAL_CREATE_ACCOUNT_STATE = {
  first_name: '',
  last_name: '',
  email: '',
  is_sso_enabled: 0,
  groups: [],
  patents_product_enabled: 0,
  patents_user_type: PATENTS_FULL_USER,
  patents_selected_roles: [],
  idea_database_product_enabled: 0,
  idea_database_roles: [],
  note: null,
  presetId: null // The only param we don't need to send with the request
} as const;

export const CREATE_ACCOUNT_VALIDATION_TRANSLATIONS = {
  first_name: 'manageAccounts.first_name',
  last_name: 'manageAccounts.last_name',
  email: 'manageAccounts.email',
  is_sso_enabled: 'manageAccounts.login_type',
  groups: 'manageAccounts.groups',
  patents_product_enabled: 'manageAccounts.patents_settings',
  patents_user_type: 'manageAccounts.patents_platform_user_type',
  patents_selected_roles: 'manageAccounts.access_rights',
  idea_database_product_enabled: 'manageAccounts.idea_database_settings',
  idea_database_roles: 'manageAccounts.idea_database_roles',
  note: 'manageAccounts.note'
} as const;

export const INITIAL_CREATE_GROUP_STATE = {
  name: '',
  description: '',
  user_ids: []
} as const;

export const INITIAL_BULK_CREATE_NOTE_STATE = {
  user_ids: [],
  note: ''
} as const;

export const CREATE_GROUP_VALIDATION_TRANSLATIONS = {
  name: 'manageGroups.group_name',
  description: 'manageGroups.description',
  user_ids: 'manageGroups.group_members'
} as const;

export const INITIAL_CREATE_ORGANIZATION_USER_PRESET_STATE = {
  name: '',
  description: null,
  is_sso_enabled: 0,
  groups: [],
  patents_product_enabled: 0,
  patents_user_type: PATENTS_FULL_USER,
  patents_selected_roles: [],
  idea_database_product_enabled: 0,
  idea_database_roles: [],
  note: null
} as const;

export const CREATE_PRESET_VALIDATION_TRANSLATIONS = {
  name: 'organization.preset_name',
  description: 'organization.preset_description',
  is_sso_enabled: 'manageAccounts.login_type',
  groups: 'manageAccounts.groups',
  patents_product_enabled: 'manageAccounts.patents_settings',
  patents_user_type: 'manageAccounts.patents_platform_user_type',
  patents_selected_roles: 'manageAccounts.access_rights',
  idea_database_product_enabled: 'manageAccounts.idea_database_settings',
  idea_database_roles: 'manageAccounts.idea_database_roles',
  note: 'manageAccounts.note'
} as const;

export const INITIAL_CREATE_TOKEN_STATE = {
  name: '',
  active: true,
  ip_limited: []
} as const;

export const INITIAL_BULK_ADD_TO_GROUPS_STATE = {
  group_ids: [],
  user_ids: []
} as const;

export const INITIAL_BULK_CHANGE_PRODUCT_ACCESS_STATE = {
  user_ids: [],
  roles: []
} as const;

export const INITIAL_UPDATE_USER_PASSWORD_STATE = {
  password: '',
  new_password: '',
  new_password_confirmation: ''
} as const;

export const INITIAL_PASSWORD_CONFIRMATION_STATE = {
  password: ''
};

// Accounts helpers
export const prepareAccountDetails = (user: User, t: TFunction) => [
  {
    translation: t('loginForm.EmailAddressText'),
    value: user.email || '-'
  },
  {
    translation: t('manageAccounts.joined_on'),
    value: user.createdAt || '-'
  },
  {
    translation: t('modals.login_method'),
    value: user.isSsoEnabled ? t('manageAccounts.SSO') : t('userProfile.Email')
  }
];

export const prepareAccountRoles = (user: User, t: TFunction) => ({
  [PRODUCT_PATENT_PLATFORM]: [
    {
      translation: t('manageAccounts.patents_platform_access'),
      value: user.patentsProductEnabled
        ? t('common.enabled')
        : t('common.disabled')
    },
    {
      translation: t('manageAccounts.patents_platform_user_type'),
      value: user.patentsUserType
        ? t(
            PATENTS_USER_TYPES[
              Number(user.patentsUserType) as keyof typeof PATENTS_USER_TYPES
            ]
          )
        : t('common.unassigned')
    },
    {
      translation: t('manageAccounts.patents_platform_access_rights'),
      value:
        user.patentsSelectedRoles.length > 0 && user.patentsProductEnabled
          ? parsePatentPlatformRoles([...user.patentsSelectedRoles], t).join(
              ', '
            )
          : t('common.unassigned')
    }
  ],
  [PRODUCT_IDEA_DATABASE]: [
    {
      translation: t('manageAccounts.idea_database_access'),
      value: user.ideaDatabaseProductEnabled
        ? t('common.enabled')
        : t('common.disabled')
    },
    {
      translation: t('manageAccounts.idea_database_roles'),
      value:
        user.ideaDatabaseRoles && user.ideaDatabaseRoles.length > 0
          ? user.ideaDatabaseRoles
              .map((role: IdeaDatabaseUserRole) =>
                t(IDEA_DATABASE_ROLES_NAMES[role])
              )
              .join(', ')
          : t('common.unassigned')
    }
  ]
});

export const prepareTokenDetails = (token: ListToken, t: TFunction) => [
  {
    translation: t('manageTokens.type'),
    value: token.accessLevel ? t(token.accessLevel) : token.accessLevel
  },
  {
    translation: t('manageTokens.token_status'),
    value: token.active ? t('common.active') : t('common.inactive')
  },
  {
    translation: t('manageTokens.allowed_ip_addresses'),
    value: token.allowedIpAddresses
  }
];

export const CREATE_TOKEN_VALIDATION_TRANSLATIONS = {
  name: 'manageTokens.name',
  access_level_id: 'manageTokens.type',
  ip_limited: 'manageTokens.ip_addresses_limited',
  active: 'manageTokens.active'
} as const;

export const ADD_NOTE_VALIDATION_TRANSLATIONS = {
  note: 'manageAccounts.note'
} as const;

export const BULK_DELETE_NOTES_VALIDATION_TRANSLATIONS = {
  user_ids: 'manageAccounts.users'
} as const;

export const BULK_DELETE_ACCOUNTS_VALIDATION_TRANSLATIONS = {
  user_ids: 'common.account'
} as const;

export const BULK_DELETE_GROUPS_VALIDATION_TRANSLATIONS = {
  group_ids: 'manageGroups.group'
} as const;

export const BULK_TOKENS_ACTIONS_VALIDATION_TRANSLATIONS = {
  token_ids: 'manageTokens.token'
} as const;

export const BULK_ADD_TO_GROUPS_VALIDATION_TRANSLATIONS = {
  ...BULK_DELETE_NOTES_VALIDATION_TRANSLATIONS,
  ...BULK_DELETE_GROUPS_VALIDATION_TRANSLATIONS
};

export const BULK_CREATE_NOTES_VALIDATION_TRANSLATIONS = {
  ...ADD_NOTE_VALIDATION_TRANSLATIONS,
  ...BULK_DELETE_NOTES_VALIDATION_TRANSLATIONS
} as const;

export const BULK_CHANGE_USER_PRODUCT_ACCESS_TRANSLATIONS = {
  ...BULK_DELETE_NOTES_VALIDATION_TRANSLATIONS,
  user_type: 'manageAccounts.patents_platform_user_type',
  roles: 'manageAccounts.user_roles'
} as const;

export const UPDATE_PASSWORD_VALIDATION_TRANSLATIONS = {
  password: 'userProfile.current_password',
  new_password: 'userProfile.new_password',
  new_password_confirmation: 'userProfile.confirm_password'
} as const;

export const PASSWORD_CONFIRMATION_VALIDATION_TRANSLATIONS = {
  password: 'userProfile.Password'
} as const;

export function SELECT_PRODUCT_OPTIONS(t: TFunction) {
  return [
    {
      value: PRODUCT_PATENT_PLATFORM,
      label: t('organization.patents_platform')
    },
    {
      value: PRODUCT_IDEA_DATABASE,
      label: t('organizationDashboard.idea_database')
    }
  ] as const;
}
